
import React, { useEffect, useState } from "react";
import Moment from 'moment'
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import styles from './FileInterface.style.css';
import BasicCard from "../FileCards/FileCards";

import { Loading } from "../Loading/Loading";

// this imports the FontAwesome Icon Styles
const API_URL = process.env.REACT_APP_API_URL;
const APP_PROTOCOL = process.env.REACT_APP_PROTOCOL;
// import FileBrowser, {Icons} from 'react-keyed-file-browser'
function FileInterface(props){
    const { getAccessTokenSilently } = useAuth0();
    // const state = {
    //     files: [
    //       {
    //         key: 'photos/animals/cat in a hat.png',
    //         modified: +Moment().subtract(1, 'hours'),
    //         size: 1.5 * 1024 * 1024,
    //       },
    //       {
    //         key: 'photos/animals/kitten_ball.png',
    //         modified: +Moment().subtract(3, 'days'),
    //         size: 545 * 1024,
    //       },
    //       {
    //         key: 'photos/animals/elephants.png',
    //         modified: +Moment().subtract(3, 'days'),
    //         size: 52 * 1024,
    //       },
    //       {
    //         key: 'photos/funny fall.gif',
    //         modified: +Moment().subtract(2, 'months'),
    //         size: 13.2 * 1024 * 1024,
    //       },
    //       {
    //         key: 'photos/holiday.jpg',
    //         modified: +Moment().subtract(25, 'days'),
    //         size: 85 * 1024,
    //       },
    //       {
    //         key: 'documents/letter chunks.doc',
    //         modified: +Moment().subtract(15, 'days'),
    //         size: 480 * 1024,
    //       },
    //       {
    //         key: 'documents/export.pdf',
    //         modified: +Moment().subtract(15, 'days'),
    //         size: 4.2 * 1024 * 1024,
    //       },
    //     ],
    //   }
    //   const handleCreateFolder = (key) => {
    //     this.setState(state => {
    //       state.files = state.files.concat([{
    //         key: key,
    //       }])
    //       return state
    //     })
    //   }
    //   const handleCreateFiles = (files, prefix) => {
    //     this.setState(state => {
    //       const newFiles = files.map((file) => {
    //         let newKey = prefix
    //         if (prefix !== '' && prefix.substring(prefix.length - 1, prefix.length) !== '/') {
    //           newKey += '/'
    //         }
    //         newKey += file.name
    //         return {
    //           key: newKey,
    //           size: file.size,
    //           modified: +Moment(),
    //         }
    //       })
    
    //       const uniqueNewFiles = []
    //       newFiles.map((newFile) => {
    //         let exists = false
    //         state.files.map((existingFile) => {
    //           if (existingFile.key === newFile.key) {
    //             exists = true
    //           }
    //         })
    //         if (!exists) {
    //           uniqueNewFiles.push(newFile)
    //         }
    //       })
    //       state.files = state.files.concat(uniqueNewFiles)
    //       return state
    //     })
    //   }
    //   const handleRenameFolder = (oldKey, newKey) => {
    //     this.setState(state => {
    //       const newFiles = []
    //       state.files.map((file) => {
    //         if (file.key.substr(0, oldKey.length) === oldKey) {
    //           newFiles.push({
    //             ...file,
    //             key: file.key.replace(oldKey, newKey),
    //             modified: +Moment(),
    //           })
    //         } else {
    //           newFiles.push(file)
    //         }
    //       })
    //       state.files = newFiles
    //       return state
    //     })
    //   }
    //   const handleRenameFile = (oldKey, newKey) => {
    //     this.setState(state => {
    //       const newFiles = []
    //       state.files.map((file) => {
    //         if (file.key === oldKey) {
    //           newFiles.push({
    //             ...file,
    //             key: newKey,
    //             modified: +Moment(),
    //           })
    //         } else {
    //           newFiles.push(file)
    //         }
    //       })
    //       state.files = newFiles
    //       return state
    //     })
    //   }
    //   const handleDeleteFolder = (folderKey) => {
    //     this.setState(state => {
    //       const newFiles = []
    //       state.files.map((file) => {
    //         if (file.key.substr(0, folderKey.length) !== folderKey) {
    //           newFiles.push(file)
    //         }
    //       })
    //       state.files = newFiles
    //       return state
    //     })
    //   }
    //   const handleDeleteFile = (fileKey) => {
    //     this.setState(state => {
    //       const newFiles = []
    //       state.files.map((file) => {
    //         if (file.key !== fileKey) {
    //           newFiles.push(file)
    //         }
    //       })
    //       state.files = newFiles
    //       return state
    //     })
    //   }





        // drag drop file component
    // function DragDropFile() {
        // drag state
        const [dragActive, setDragActive] = React.useState(false);
        const [loading, setLoading] = useState(false);
        
        // handle drag events
        const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
        };
        // triggers when file is dropped
        const handleDrop = function(e) {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // at least one file has been dropped so do something
                handleFiles(e.dataTransfer.files);
            }
        };
        // triggers when file is selected with click
        const handleChange = function(e) {
            e.preventDefault();
            if (e.target.files && e.target.files[0]) {
            // at least one file has been selected so do something
                handleFiles(e.target.files);
            }
        };
        const onButtonClick = () => {
            inputRef.current.click();
          };
        const inputRef = React.useRef(null);

        const handleFiles = async function(file){
            var formData = new FormData();
            setLoading(true);
            let selectedFile = file[0];
            console.log(selectedFile)
            console.log(selectedFile.type)

            const allowedTypes = ['images/png','images/pdf','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/pdf','text/plain','application/msword']
            if (!allowedTypes.includes(selectedFile?.type )){
                alert("chosen file type is not supported by the server for upload, if you believe this is in error, please contact support.")
            }
            formData.append("file",selectedFile);
            const accessToken = await getAccessTokenSilently();
                

                const request_config = {
                    headers: {
                        'Content-Type': selectedFile.type,
                        authorization: `Bearer ${accessToken}`,
                    }
                }

            let response = await axios.post(APP_PROTOCOL+"://"+API_URL+"/v1/user/document/upload", formData,request_config)
            setLoading(false);
            props.refreshFiles()
        }
        

      return (
        // <FileBrowser
        //     files={[]}
        //     icons={Icons.FontAwesome(4)}
        // />
        // <div>Comming soon.</div>
        
        <div className="flex justify-center content-center flex-wrap w-screen h-screen">
            <div>
            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                    <div>
                    {loading? <Loading />: <><p>Drag and drop your file here or</p><button className="upload-button" onClick={onButtonClick}>Upload a file</button></>}
                    
                    
                    </div> 
                </label>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>
            </div>
        </div>
        //   <FileBrowser
        //     files={this.state.files}
        //     icons={Icons.FontAwesome(4)}
    
        //     onCreateFolder={this.handleCreateFolder}
        //     onCreateFiles={this.handleCreateFiles}
        //     onMoveFolder={this.handleRenameFolder}
        //     onMoveFile={this.handleRenameFile}
        //     onRenameFolder={this.handleRenameFolder}
        //     onRenameFile={this.handleRenameFile}
        //     onDeleteFolder={this.handleDeleteFolder}
        //     onDeleteFile={this.handleDeleteFile}
        //   />
        )
      
}
export default FileInterface;


// const mount = document.querySelectorAll('div.demo-mount-nested-editable')
// ReactDOM.render(
//   <NestedEditableDemo />,
//   mount[0]
// )