import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-black_trimmed.png";


function Header() {
    return (
        // <div className=" Header flex h-12 mb-4">
        //     <div className="w-full bg-gray-500 h-12">
        //         {/* <h1 className='text-3xl font-bold' > Sovereign AI</h1> */}
        //         <img className="" src={logo}></img>
        //     </div>
        // </div>

        <div className=" bg-gray-500 h-12">
        <Link to="/">
            {/* <h1 className='text-3xl font-bold' > Sovereign AI</h1> */}
            <img className="object-scale-down max-w-xs" src={logo}></img>
        </Link>
        </div>
    );
  }
  
  export default Header;
  