// import React, { useState, useEffect, useRef } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import { TransitionGroup } from 'react-transition-group';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faSpinner, faMessage, faChalkboard,faCircleQuestion,faAsterisk } from '@fortawesome/free-solid-svg-icons'
// import { Popover } from 'react-tiny-popover'
import { useMarkdownProcessor } from '../../hooks/use-markdown';
import ReactMarkdown from 'react-markdown'
import rehypeHighlight from "rehype-highlight";
import rehypeReact from "rehype-react";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import Collapse from '@mui/material/Collapse';
import MsgOptionButtons from '../MsgOptionButtons/MsgOptionButtons';
import {BasicSpeedDial,StyledSpeedDial} from '../SpeedDial/SpeedDial';

function iconSwitch(iconType="announcement"){
    switch(iconType){
        case 'announcement':
            return <FontAwesomeIcon className="pt-1 pr-1" icon={faBullhorn} />;
        case 'user':
            return <FontAwesomeIcon className="pt-1 pr-1" icon={faMessage} />;
        case 'response':
            return <FontAwesomeIcon className="pt-1 pr-1" icon={faChalkboard} />;
        case 'loading':
            return <FontAwesomeIcon className="pt-1 pr-1" icon={faSpinner} spinPulse />
        default:
            return <FontAwesomeIcon className="pt-1 pr-1" icon={faCircleQuestion} />;

    }
}

const FRUITS = [
    '🍏 Apple',
    '🍌 Banana',
    '🍍 Pineapple',
    '🥥 Coconut',
    '🍉 Watermelon',
  ];
  

function Reference(references){
    if (references !== null && references !== undefined && references.length > 0) {
    //   return (<span onClick={handleRefClick(references)}><FontAwesomeIcon icon={faAsterisk} /></span>)
    return (
    <>
    
    </>
    )
    } else {
      return (<span></span>)
    }
}   
function renderItem({ item, handleRemoveFruit }) {
    return (
      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            title="Delete"
            onClick={() => handleRemoveFruit(item)}
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemText primary={item} />
      </ListItem>
    );
  }

  
export default function ResponseMsg(props){
    let {message} = props;
    // let content  = useMarkdownProcessor(message.content) 
    let content = message.content

    
//     const [fruitsInBasket, setFruitsInBasket] = React.useState(FRUITS.slice(0, 3));
//     const handleAddFruit = () => {
//       const nextHiddenItem = FRUITS.find((i) => !fruitsInBasket.includes(i));
//       if (nextHiddenItem) {
//         setFruitsInBasket((prev) => [nextHiddenItem, ...prev]);
//       }
//     };
//     const handleRemoveFruit = (item) => {
//       setFruitsInBasket((prev) => [...prev.filter((i) => i !== item)]);
//     };
  
//     const addFruitButton = (
//       <Button
//         variant="contained"
//         disabled={fruitsInBasket.length >= FRUITS.length}
//         onClick={handleAddFruit}
//       >
//         Add fruit to basket
//       </Button>
//     );
  
//     return (
//       <div>
//         {addFruitButton}
//         <List sx={{ mt: 1 }}>
//           <TransitionGroup>
//             {fruitsInBasket.map((item) => (
//               <Collapse key={item}>{renderItem({ item, handleRemoveFruit })}</Collapse>
//             ))}
//           </TransitionGroup>
//         </List>
//       </div>
//     );
//   }
  
    return (
        <div  key = {message.ind} className='ResponseBox'> {iconSwitch(message.role)} 
            <div>
                <ReactMarkdown children = {content} 
                            remarkPlugins={[remarkParse,remarkGfm,remarkRehype]}
                            rehypePlugins={[rehypeReact,rehypeHighlight]}
                            ></ReactMarkdown>
            </div> 
            {Reference(message.references)}
            {/* <BasicSpeedDial></BasicSpeedDial>                 */}
        </div>
    )
}

// export default ResponseMsg;
