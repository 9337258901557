import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import Header from '../components/Header/Header';
import ResponseBox from '../components/ResponseBox';
import InputBox  from '../components/InputBox';
import Sidebar  from '../components/Sidebar';
import PropTypes from 'prop-types';
import Tabs from '../components/TabComponent/Tabs';
import TabButton from '../components/TabComponent/TabButtons';
import FileInterface from '../components/FileInterface/FileInterface';
import FileCards from '../components/FileCards/FileCards';
import { Loading } from "../components/Loading/Loading";


import { Link } from "react-router-dom";



//hooks
// import useInterval from "../hooks/use-interval.hook";
// import useRetryUntilResolved from "../hooks/use-retry-until-resolved.hook";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage, faPlus, faPlusCircle, faMegaphone } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { NavBar } from "../components/navigation/desktop/nav-bar";
import { MobileNavBar } from "../components/navigation/mobile/mobile-nav-bar";
import { TbVariable } from 'react-icons/tb';
import remove from 'lodash/remove';
import { set } from 'lodash';
import CustomizedSnackbars from '../components/Snackbar/Snackbar';
const { unionBy } = require('lodash/array');


// import { useUser } from '@auth0/nextjs-auth0/client';
// const dotenv = require('dotenv');import { useUser } from '@auth0/nextjs-auth0/client';
// dotenv.config();
// require("dotenv").config();
// const server_url = process.env.SERVER_URL;
const API_URL = process.env.REACT_APP_API_URL;
const APP_PROTOCOL = process.env.REACT_APP_PROTOCOL;
var threadInit = false;
var fileInit = false;
export const Main = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { isAuthenticated, getIdTokenClaims } = useAuth0();
    const { user } = useAuth0();
    
    var timeOut = 500;
    // const { user, error, isLoading } = useUser();
    const [accessToken, setAccessToken] = useState(null);
    const [conversation, setConversation] = useState([]);
    const [prompt, setPrompt] = useState([]);
    const [FileAccess, setFileAccess] = useState(false);
    const [activeThread,setActiveThread] = useState(null);
    const [threadAwaitingResponse, setThreadAwaitingResponse] = useState(null);
    const [waitingQuestion, setWaitingQuestion] = useState(null);
    const [activeTab,setActiveTab] = useState(0);
    const [threads, setThreads] = useState([]);
    const [Snackbar, setSnackBar] = useState(false);
    const [SnackbarMsg, setSnackBarMsg] = useState("Ready");
    // var Model = useRef("gpt");
    
    const [files, setFiles] = useState([]);
    const [blocking, setBlocking]= useState("loading");

    const threadsReady = useRef(false);
    
    // ["gpt","llama2"]
    const [Model,setModel]= useState("gpt");

    // const loadingMsg = {"role":"loading", "id":-1, "content":"Loading..."}
    const loadingMsg = (id) =>{ return([{"role":"loading", "id":id, "content":"Loading..."}])}
    // useAuth0.parseHash((err, authResult) => {
    //     if (authResult) {
    //       window.location.hash = '';
    //       localStorage.setItem('token', authResult.accessToken);
    //       console.log("auth",authResult)
    //     } else if (err) {
    //         console.log(err)
    //     }
    //   });

    useEffect(() => {
        // if (threads.length == 0 && threadInit == false){
        console.log("auth",getAccessTokenSilently())
        // }
        initThreads()
        
        initFiles()
      }, []);

      useEffect(() => {
        console.log("updatedthreads:",threads)
      }, [threads]);

    function handleSnackbar(value){
        console.log("handle snackbar", value)
        setSnackBar(value)
    }
    function triggerSnackbar(msg="", type="success"){
        setSnackBarMsg(msg)
        handleSnackbar(true)

    }
    //   useEffect(() => {
    //     if (threadAwaitingResponse === activeThread && activeThread !== null){
    //         setConversation([...conversation, {"role":"loading", "id":-1, "content":"Loading..."}])
    //     }
    //   },[threadAwaitingResponse,activeThread]);

    useEffect(() => {
        console.log("active thread changed")
        let currentThread = getCurrentThread()
        if (activeThread !== threadAwaitingResponse){
            console.log("basic")
            setConversation(currentThread.messages)
        }else if (activeThread === threadAwaitingResponse && waitingQuestion !== null){
            console.log("waiting msg", currentThread)
            
            setConversation([...currentThread.messages, waitingQuestion,{"role":"loading", "id":-1, "content":"Loading..."}] )
        }else if (currentThread.messages !== undefined){
            console.log("not undefined")
            setConversation(currentThread.messages)
        }

        
        // if (threadAwaitingResponse === activeThread && waitingQuestion !== null && activeThread !== null){
        //     console.log("waiting msg + loading")
        //     setConversation([...conversation, waitingQuestion, {"role":"loading", "id":-1, "content":"Loading..."}])
        // }
      }, [threadAwaitingResponse,waitingQuestion,activeThread]);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );


    function doThing(){
        console.log("do thing")
        // if (Model == "gpt"){
        //     setModel("llama")
        //     console.log("setting model to: llama")
        // }else if(Model == "llama"){
        //     setModel("gpt")
        //     console.log("setting model to: gpt")
        // }
        // refreshThreads()
        setSnackBarMsg("Thread Deleted")
        triggerSnackbar("Thread Deleted", "success")
        return true
    }


    function handleThreadChange(newThread, pastId = null,callback=()=>{}){
        console.log("handle thread change", newThread, threads)
        let id = pastId == null ? newThread.id : pastId
        console.log("using id: ", id)
        setThreads(currentThreads => {
            let newThreads =  currentThreads.map(thread => {
                if (thread.id === id){
                    console.log("updating previous thread with id: ", id, newThread)
                    return {
                        ...newThread,
                    };
                } else {
                    return thread;
                }
            
            })
            return (newThreads.sort((a, b) => (a.updated_when < b.updated_when) ? 1 : -1))
        })
        // setThreads(threads.map(thread => {
        //     if (thread.id === id){
        //         console.log("updating previous thread with id: ", id, newThread)
        //         return {
        //             ...newThread,
        //         };
        //     } else {
        //         return thread;
        //     }
        // }),callback())
    // }))
    }

    /*
    *    create a new thread in the state object
    *    this function does not create a thread on the server, only in the state object
    *    use saveNewChat to create a thread on the server
    */
    function CreateStateThread(thread){
        console.log("createstatethread")
        try{
            setThreads([thread, ...threads]);
        }catch (error){
            console.log("error in createStateThread", error)
            return false
        }
    }

    function UpdateStateThread(newThread, pastId = null){
        // update a specific thread inside the state object
        try{
            if (pastId == null){    // if a new id is not provided then use the one in the thread object
                pastId = newThread.id
            }
            // update the thread array with the newThread object matching by id
            if (activeThread === pastId){
                setActiveThread(newThread.id)
            }
        }
        catch (error){
            console.log("error in updateStateThread", error)
            // alert("error updating thread, please try again later")
            return false
        }
        console.log("updatestatethread", newThread, pastId)
        let tempThreads = threads.map((thread) => (thread.id === pastId ? newThread : thread))
        console.log(tempThreads)
        // setThreads(tempThreads);

    }

    function DeleteStateThread(id){  
        // remove a specific thread from the state object
        // NOTE THIS FUNCTION DOES NOT REMOVE THREADS FROM THE SERVER, only from the state object. 
        // use deleteThread to remove a thread from the server
        // let tempThreads = threads.filter((thread) => thread.id !== id)
        console.log("DeleteStateThread")
        if (activeThread === id){
            setActiveThread(null)
            console.log("active thread deleted")
        }
        triggerSnackbar("Thread Deleted", "success")
        // setThreads(threads.filter((thread) => thread.id !== id));
    }

    
    async function initThreads(){
        // the initial load of threads from the server
        console.log("initthreads")
            while ( threadsReady.current !== true){
                let newThreads = await getThreads()
                
                if (newThreads === false){
                    timeOut *= 1.2
                    console.log("error getting threads, waiting to try again in "+timeOut+"ms")
                    await delay(timeOut)
                    // return false
                }else{
                    threadsReady.current = true
                    timeOut = 500
                    console.log("thread initialization successful")
                    let updatedThreads = unionBy(newThreads, threads, 'id')
                    updatedThreads.sort((a, b) => (a.updated_when < b.updated_when) ? 1 : -1)
                    setThreads(updatedThreads)
                    threadInit = true;
                }
                
            }
        return []
    }
    async function refreshThreads(){
        let newThreads = await getThreads()
        if (newThreads === false){
            timeOut *= 1.2
            // console.log("error getting threads, waiting to try again in "+timeOut+"ms")
            await delay(timeOut)
            // return false
        }else{
            timeOut = 500
            console.log("thread update successful")
            let activeExists = false
            setThreads(newThreads)
            threads.map((thread) => (thread.id === activeThread ? activeExists : true))
            if (activeExists === false){
                setActiveThread(null)
            }
            //if activeThread is not in newThreads.id set it to null

            // setThreads(newThreads)
        }
    }

    async function initFiles(){
        // the initial load of threads from the server
            while (fileInit !== true){
                let newFiles = await getFiles()
                if (newFiles === false){
                    timeOut *= 1.2

                    // console.log("error getting files, waiting to try again in "+timeOut+"ms")
                    
                    await delay(timeOut)
                }else{
                    timeOut = 500
                    console.log('new files', newFiles)
                    let updatedFiles = unionBy(newFiles, files, 'source')
                    // console.log('updated files', updatedFiles)
                    setFiles(updatedFiles)
                    fileInit = true;
                }
                
            }
        return []
    }

    async function refreshFiles(){
            let newFiles = await getFiles()
            if (newFiles === false){
                timeOut *= 1.2

                // console.log("error getting files, waiting to try again in "+timeOut+"ms")

                await delay(timeOut)
            }else{
                timeOut = 500
                console.log('new files', newFiles)
                let updatedFiles = unionBy(newFiles, files, 'source')
                // console.log('updated files', updatedFiles)
                setFiles(updatedFiles)
                fileInit = true;
            }
            
    }

    var welcomeMsg = [ {"role":"announcement", "id":0, "content":"Welcome to SovereignAI, start typing below and Sovereign will do its best to answer you."},
                         {"role":"announcement", "id":1,"content":"Sovereign provides answers based on its learned knowledge and the local knowledge you provide it."}]

    async function getFiles(){
        //get the list of threads for this user
        // console.log("get threads")
        try {
            const accessToken = await getAccessTokenSilently();
            const request_config = {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            }
            let response = await axios.post(APP_PROTOCOL+"://"+API_URL+"/v1/user/documents", ({"files":"1"}),request_config)
            // console.log("files", response)
            if (response.status !== 200){
                console.log("response",response)
                // alert ("server isn't responding, please try again later \n error code: "+response.status)
            }
            // console.log("threads response", loadChatfromHistory(response.data))
            return loadFiles(response.data)
        } catch (error) {
            if (error == "Error: Please verify your email before logging in."){
                // alert("Please verify your email before logging in.")    
                setBlocking("verify") 
                triggerSnackbar("Please verify your email before logging in.")
            }else{
                // alert ("getFiles: something went wrong connecting to the server, please try again later \n error code: "+error)
                // console.error("Error sending prompt", error);
            }
            return false
        }
    };

    async function getThreads(){
        //get the list of threads for this user
        console.log("get threads")
        try {
            const accessToken = await getAccessTokenSilently();
            const request_config = {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            }
            console.log(request_config)
            let response = await axios.post(APP_PROTOCOL+"://"+API_URL+"/v1/user/threads", ({"thread_start":"1"}),request_config)
            console.log(response)
            if (response.status !== 200){
                console.log("response",response)
                alert ("server isn't responding, please try again later \n error code: "+response.status)
            }else if ((response.status !== 200)){
            }
            setBlocking(false)
            return loadChatfromHistory(response.data)
        } catch (error) {
            // console.log("error in getThreads"+ error)
            if (error == "Error: Please verify your email before logging in."){
                // alert("Please verify your email before logging in.")    
                setBlocking("verify")
            }else{
                // alert ("getThreads: something went wrong connecting to the server, please try again later \n error code: "+error)
                console.error("Error sending prompt", error);
            }
            
            return false
        }
    };


    async function deleteThread(id){
        const thread = getCurrentThread(id)
        if (thread !== false){
            console.log("delete thread: ", thread)
            if (thread.id === "newThread"){
                DeleteStateThread("newThread")
                refreshThreads()
                return true
            }
            const accessToken = await getAccessTokenSilently();
            var request_config = {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            }
            try{
                axios.post(APP_PROTOCOL+"://"+API_URL+"/v1/user/deleteThread", {"id":id},request_config)
                    .then((response)=>{console.log("thread deleted: response ",response)})
                    .catch((error)=>{console.log("error deleting thread", error)
                        alert("error deleting new thread, please try again later")
                    })
                DeleteStateThread(thread.id)
                
            } catch (error){
                console.log("error deleting thread", error)
                refreshThreads()
            } finally {
                console.log("finally")
                refreshThreads()
            }
        }
    }

    function loadChat(title="New Chat", messages=[], id = null){
        // create a new chat
        if (id == null){
            id = threads.length
        }
        const thread = {"id":id,"created_by":"user","modified_when":"", "title":title,"messages":messages};
        return thread
    }

    function loadFile(title="UnknownTitle"){
        // create a new chat
        const file = {title:title,source:"local",pages:'1',content:""};
        return file
    }
    function loadFiles(data){
        console.log("load files")
        var newFiles =[]
        for (const file in data){

            // console.log(data[file])
            // console.log(data[file])
            
            // let title = data[file]._id
            let source = data[file]._id.split('\\').pop().split('/').pop();
            let title = source
            let pages = data[file].pages
            let fileStatus = data[file].fileStatus
            let newFile = {"title":title,'source':source,"embeddings":data[file].embeddings,"pages":pages, "fileStatus":fileStatus};
            newFiles.push(newFile);
            // console.log("new threads", newThreads)
        }
        // setThreads([newThreads, ...threads]);
        // setThreads(updatedThreads)
        // console.log(newFiles)
        return newFiles
    }


    function loadChatfromHistory(data){
        // console.log(data)
        var newThreads =[]
        for (const thread in data){
            // console.log(data[thread].title)
            // const newThread = loadChat(data[thread].title, data[thread].messages, data[thread]._id)
            
            const newThread = {"id":data[thread]._id,"created_when":data[thread].created_when,"updated_when":data[thread].updated_when, "title":data[thread].title,"messages":data[thread].messages};
            newThreads.push(newThread);
            // console.log("new threads", newThreads)
        }
        // setThreads([newThreads, ...threads]);
        // setThreads(updatedThreads)
        return newThreads
    }

    function newChatButton(title="New Chat", startingMessages=[]){
        var newThreadId = "newThread";
        console.log("new Chat, id is: ",newThreadId)
        let now = new Date().toISOString()

        const currentThread = {"id":newThreadId,"created_by":"user","created_when":now, "updated_when":now, "title":title,"messages":startingMessages};
        // new chat should be at the top of the list of chats
        
        // remove the new chat if it already exists
        setThreads([currentThread, ...threads]);
        
        setActiveThread(newThreadId)
        // setConversation([...startingMessages,{"role":"loading", "id":-1, "content":"Loading..."}]);
        return currentThread
    }

    function newThread(title="New Chat", startingMessages=[]){
        // create a new chat
        var newThreadId = "newThread";
        // check if new chat already exists
        console.log('newThread')
        let now = new Date().toISOString()

        let thread = {"id":newThreadId,"created_by":"user","created_when":now, "updated_when":now, "title":title,"messages":startingMessages};
        return thread
        // if (getCurrentThread(newThreadId) !== false){
        //     // new chat already exists, instead make it active and return the id
        //     // console.log(getCurrentThread(newThreadId))
        //     setActiveThread(newThreadId)
        //     console.log('already existing newChat')
        //     return newThreadId
        // }else{
        //     console.log("new Chat, id is: ",newThreadId)
        //     let now = new Date()

        //     const thread = {"id":newThreadId,"created_by":"user","created_when":now, "updated_when":now, "title":title,"messages":[]};
        //     // new chat should be at the top of the list of chats
            
        //     // remove the new chat if it already exists

        //     setThreads([thread, ...threads]);
            
        //     setActiveThread(newThreadId)
        //     setConversation([...startingMessages,{"role":"loading", "id":-1, "content":"Loading..."}]);
        //     return thread
        // }
        
        // change the active chat to the new chat
    }

    async function saveNewChat(currentThread, title="New Chat"){
        console.log("save new chat")
        console.log("activeThread ",activeThread)
        // save the new chat to the server
        if (activeThread === "newThread" || activeThread === null){
            console.log("no active thread")
            currentThread.title = title
            console.log("save new chat", currentThread)
            const accessToken = await getAccessTokenSilently();
            

            const request_config = {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            }
            // console.log("get new thread ID")
            axios.post(APP_PROTOCOL+"://"+API_URL+"/v1/user/newThread", {"messages":currentThread.messages}, request_config)
                .then((response)=>{
                    // let newThread = getCurrentThread("newThread")
                    let newThread = response.data
                    console.log("new thread", newThread)
                    // removeStateThread("newThread")
                    console.log("current threads", threads)
                    // setThreads(threads.map(thread => {
                    //     if (thread.id === "newThread"){
                    //         return {
                    //             ...thread,
                    //             messages: newThread.messages,
                    //             title: newThread.title,
                    //         };
                    //     } else {
                    //         return thread;
                    //     }}))
                    handleThreadChange(newThread, "newThread",()=>{console.log("saved", threads);setActiveThread(newThread.id)})
                    // setThreads([newThread, ...threads], setActiveThread(newThread.id))
                    // UpdateStateThread(newThread)
                    setActiveThread(newThread.id)
                    triggerSnackbar("Thread Saved", "success")
                    return true
                })
                .catch((error)=>{console.log("error saving new thread", error)
                triggerSnackbar("Error saving new thread, please try again later", "error")
                alert("error saving new thread, please try again later")
                return false
            });
            return true
            
        }else{
            currentThread.title = title
            console.log("save new chat", currentThread)
            const accessToken = await getAccessTokenSilently();
            

            const request_config = {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            }
            // console.log("get new thread ID")
            axios.post(APP_PROTOCOL+"://"+API_URL+"/v1/user/newThread", {"messages":currentThread.messages}, request_config)
                .then((response)=>{
                    // let newThread = getCurrentThread("newThread")
                
                    // console.log("new thread", newThread)

                    let newThread = response.data
                    console.log("new thread", newThread)
                    // removeStateThread("newThread")
                    // UpdateStateThread(newThread, "newThread")
                    handleThreadChange(newThread, "newThread",()=>{console.log("saved", threads);setActiveThread(newThread.id)})



                // thread.id = response.data
                // let getCurrentThread(activeThread)

                // let currentThread = Object.assign({}, threads["newThread"]);
                // currentThread.id = response.data
                // console.log("current thread", currentThread)
                // let tempThreads = threads
                // tempThreads['newThread'] = currentThread
                    // setThreads(tempThreads)

                // console.log("new thread ID", response)
                // initThreads()
                // let currentThreadIndex = threads.findIndex((thread) => thread.id === threadId);
                // threads[currentThreadIndex].id = response.data.threadId
                // thread.id = response.data.thread_id    
                return true
                })
                .catch((error)=>{console.log("error saving new thread", error)
                alert("error saving new thread, please try again later")
                return false
            });
            
            return true
            
        }
        

    }
    async function getRequest_config(){
        const accessToken = await getAccessTokenSilently();
            

        const request_config = {
            headers: {
                authorization: `Bearer ${accessToken}`,
            }
        }
        return request_config
    }
    function handleResponse(currentThread, data, response){
        /// handle the response from the server
        console.log("response", response,threads)
                try {                   
                    for (var i in response.data.choices){
                        console.log("content", response.data.choices[i].message.content);
                        // console.log(currentThread)
                        let now = new Date().toISOString()                                             
                        let newMessages = [{"role":"user", "id":currentThread.messages.length, "content":data},{"role":"response", "id":currentThread.messages.length, "content":response.data.choices[i].message.content, "references":response.data.references}]
                        currentThread.messages = [...currentThread.messages, ...newMessages]  
                        currentThread.updated_when = now          
                        console.log("Updated Thread: ",currentThread)
                        if (currentThread.id === "newThread"){
                            console.log("saving new thread")
                            handleThreadChange(currentThread)
                            saveNewChat(currentThread);
                        }else{
                            handleThreadChange(currentThread)
                            updateThread(currentThread.id, newMessages)
                        }
                    }    
                    // UpdateStateThread(currentThread)
                    // setConversation(currentThread.messages);
                    setThreadAwaitingResponse(null)
                    setWaitingQuestion(null)

                    // if (currentThread.id === "newThread"){                    
                        // saveNewChat(currentThread);
                    // }
                }catch (error) {
                    console.log("error in response", error)
                    alert("error in response, please try again later")
                }
    }
    const handleModelChange = (event) => {
        console.log("handle main", event)
        setModel(event)
      }

    function handleConversation(data){
        var _activeThread = activeThread
        var currentThread = null;
        if (activeThread == null){
            console.log("no conversation selected, start a new convo");
            let message = {"role":"user", "id":0, "content":data}
            
            currentThread = newChatButton("New Chat", [])
            console.log("new thread: ", currentThread)

            setActiveThread(currentThread.id)
            setWaitingQuestion(message)
            setThreadAwaitingResponse(currentThread.id)
        }else{
            currentThread = getCurrentThread(activeThread)
            if (currentThread !== false){
                console.log("current thread", currentThread)
                setWaitingQuestion({"role":"user", "id":currentThread.messages.length, "content":data})
                setThreadAwaitingResponse(activeThread)
            }
        }
        if (FileAccess){
            console.log("sending prompt file context")
            sendPromptWithExtra(data).then((response)=> {
                // currentThread = getCurrentThread(_activeThread)
                
                handleResponse(currentThread, data, response)
            });
        }else{
            console.log("sending prompt with no file context")
            
            sendPrompt(data).then((response)=> {
                // currentThread = getCurrentThread(_activeThread)
                console.log("prompt threads",threads)
                handleResponse(currentThread, data, response)
            });
        }
         
    }

    async function updateFileStatus(file_id, status){
        //// change the file status for a user's file to allow them to access the file in the search
        console.log("update File Status!")
        const accessToken = await getAccessTokenSilently();

                const request_config = {
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    }
                }
            let response = axios.post(APP_PROTOCOL+"://"+API_URL+"/v1/user/updateFileStatus", {"source":file_id, "fileStatus":status},request_config)
            
    }

    /*
    * update an existing thread in the DB, providing the new messages to append to the threads list of messages.
    */
    async function updateThread(thread_id, newMessages){
            console.log("update Thread!")
            console.log("update message", newMessages)
            const accessToken = await getAccessTokenSilently();
                const request_config = {
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    }
                }
            let response = axios.post(APP_PROTOCOL+"://"+API_URL+"/v1/user/updateThread", {"id":thread_id, "messages":newMessages},request_config)
    }

    /* function to get the most recent threads outside of function context. 
    *  to solve this issue, https://stackoverflow.com/questions/57952137/react-state-hook-doesnt-properly-handle-async-data?rq=3
    */
    function refreshThreadState(){
        return setThreads(currentThreads => { return currentThreads })
    }
    function getCurrentThread(id = activeThread){
        // get the current thread as indicated by the activethread state. 
        // this function simplfies the call to find and convert the active thread ID into an index.
        // TODO simplify this into a search for id rather then have to do the id => index stuff.

        refreshThreadState()

        try{
            let currentThreadIndex = threads.findIndex((thread) => thread.id === id);
            if (currentThreadIndex === -1){
                console.log("no thread found with id: "+id+" return false")
                return false
            }
            return Object.assign({"messages":[]}, threads[currentThreadIndex]);
        }catch (error){
            console.log("error in getCurrentThread", error)
            return false;
        }
    }
    
    function updateCurrentThread(response){
        // take a API response and update the current thread with the new data.
        
        console.log("response", response)
        console.log("thread",activeThread)
        const currentThreadIndex = threads.findIndex((thread) => thread.id === activeThread);
        const currentThread = Object.assign({}, threads[currentThreadIndex]);
        try {
            for (var i in response.data.choices){
                console.log("res", response.data.choices[i]);
                console.log("content", response.data.choices[i].message.content);
                currentThread.messages = [...currentThread.messages, {"role":"response", "id":currentThread.messages.length, "content":response.data.choices[i].message.content}]            
            }    
            const tempThreads = threads;
            tempThreads[currentThreadIndex] = currentThread;
            // setThreads(tempThreads);
            console.log(currentThread.messages)
            // setConversation(currentThread.messages);
        }catch (error) {
            console.log("error in response", error)
        }
    }

    async function sendPrompt(data){
        try {
            const accessToken = await getAccessTokenSilently();
            const request_config = {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            }
            console.log("send prompt with :"+JSON.stringify({"model":Model,"messages":[{"role": "user", "content": data}]}))
            return axios.post(APP_PROTOCOL+"://"+API_URL+"/v1/chat/completion", {"model":Model,"messages":[{"role": "user", "content": data}]},request_config)
        } catch (error) {
            console.error("Error sending prompt", error);
            return "this was an error"
        }
    };

    async function sendPromptWithExtra(data){
        try {
            const accessToken = await getAccessTokenSilently();
            const request_config = {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            }
            const currentThreadIndex = threads.findIndex((thread) => thread.id === activeThread);
            const pastConversation = threads[currentThreadIndex].messages
            console.log("past",pastConversation)            
            const Message = (pastConversation)
            console.log("messages", Message)

            console.log("send prompt + file access with :"+JSON.stringify({"model":Model,"messages":[{"role": "user", "content": data}]}))
            return axios.post(APP_PROTOCOL+"://"+API_URL+"/v1/chat/completion_with_context", {"model":Model,"messages":[{"role": "user", "content": data}]},request_config)
        } catch (error) {
            console.error("Error sending prompt", error);
            return "this was an error"
        }

        // try {
        //     // { message: prompt }
        //     console.log("send prompt with extra:"+JSON.stringify({data}))
        //     const currentThreadIndex = threads.findIndex((thread) => thread.id === activeThread);
        //     // const Message = []
        //     const pastConversation = threads[currentThreadIndex].messages
        //     console.log("past",pastConversation)            
        //     const Message = (pastConversation)
        //     console.log("messages", Message)
        //     // Message.push({"role": "user", "content": data})
        //     // const tempMsg = {"role": "user", "content": data}
        //     // console.log(server_url)
        //     axios.post("127.0.0.1:8000/v1/test", {"model":"gpt","messages":Message}).then((response)=> {
        //     // axios.post("http://localhost:8000/v1/test", {"model":"gpt","messages":tempMsg}).then((response)=> {
        //         updateCurrentThread(response);
        //         // return response
        //         });             
        // } catch (error) {
        //     console.error("Error sending prompt", error);
        //     return "this was an error"
        // }

    };

    function ResponseDisplay(){
        console.log("responseDisplay",activeThread);
        // console.log("response threads",threads)
        
        
        if (activeThread == null){
            return (<ResponseBox conversation={welcomeMsg} FileAccess ={FileAccess} setFileAccess = {setFileAccess} updateConversation={handleConversation} handleModelChange={handleModelChange} Model={Model}/>)            
        }else{           
            // const currentThreadIndex = threads.findIndex((thread) => thread.id === activeThread);
            // const currentThread = Object.assign({}, threads[currentThreadIndex]);
            let currentThread =getCurrentThread(activeThread)

            // if starting the page then initialise the conversation.
            if (conversation && conversation.length == 0){
                // setConversation(currentThread.messages)
            }
            
            // console.log(currentThread);
            if ((currentThread)==null || (currentThread)=== false){
                console.log("thread null")
                return (<ResponseBox conversation={welcomeMsg} FileAccess ={FileAccess} setFileAccess = {setFileAccess} updateConversation={handleConversation} handleModelChange={handleModelChange} Model={Model}/>)
            }else if(currentThread.messages.length > 0 ){
                // console.log("some messages in thread")
                return (<ResponseBox conversation={conversation} FileAccess ={FileAccess} setFileAccess = {setFileAccess} updateConversation={handleConversation}handleModelChange={handleModelChange} Model={Model}/>)
                
            }else{
                // console.log("no messages in thread")
                console.log("conversation", conversation)
                return (<ResponseBox conversation={[...welcomeMsg, ...conversation]} FileAccess ={FileAccess} setFileAccess = {setFileAccess} updateConversation={handleConversation}handleModelChange={handleModelChange} Model={Model}/>)
            }
        }
    }

    function DoThingButton(){
        // console.log("process env", process.env.NODE_ENV )
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return (<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={doThing}>
                    {Model}
                    </button>)
        } else {
            return (<></>)
        }
    }

    function doNothing(){
        /// function to pass to elements that should be disabled.
    }
    function exitOverlay(){
        console.log("exit overlay")
        // setBlocking("loading")
    }
    function GreyOverlay(props){
        let message = "";
        if (blocking === "verify"){
            message = ["Warning your account is not verified.",<br></br>, "Please check your email and verify your account before using SovereignAI."]
        }else if (blocking === "loading"){
                    
            message = [<Loading/>,<br></br>,"Loading SovereignAI..."]
        }


        // return(<>Warning your account is not verfified, please check your email and verify your account before using SovereignAI.</>)
        return (<div id="greyOverlay" className={`${props.ready != true && 'hidden'} fixed z-10 w-full h-full opacity-95 flex justify-center items-center bg-gray-900  duration-700`}>
        {/* <button 
            class="fixed top-16 right-8 text-white hover:text-amber-500 text-7xl font-semibold duration-300"
            onclick={()=> {exitOverlay()}}>&times;</button> */}
        <div class="flex flex-col text-white text-center text-xl font-light space-y-3 items-center">
            {message}
        </div>
    </div>)
    }

    function TabbedContent(){
        // let fileAccess = false;
        if (blocking){
            console.log("blocking",blocking)
            /// user is not email verfified or for some reason should not have access. 
            return (<>
                <GreyOverlay ready={true}> </GreyOverlay>
                <Sidebar threads={[]}
                        activeTab = {activeTab}
                        activeThread = {activeThread}
                        setThreads={doNothing}
                        newChat = {doNothing}
                        setActiveThread={doNothing}
                        deleteThread={doNothing} 
                />
                    <div className='Content flex-1 w-7/8 flex flex-col p-0 bg-gray-100 max-h-full '>
                        {/* <div className='flex flex-col'> */}
                            <ResponseDisplay></ResponseDisplay>
                            {/* <ResponseBox conversation={threads[activeThread].messages} updateConversation={handleConversation}/> */}
                            <InputBox handleConversation={doNothing} FileAccess ={doNothing} setFileAccess = {doNothing}/>
                        {/* </div> */}
                    </div>                
            </>)
        }
        if (activeTab === 0){
            return (
            <>
                <GreyOverlay ready={false}> </GreyOverlay>

                <Sidebar threads={threads}
                        activeTab = {activeTab}
                        activeThread = {activeThread}
                        setThreads={doNothing}
                        newChat = {newChatButton}
                        setActiveThread={setActiveThread}
                        deleteThread={deleteThread} 
                        
                />
                    <div className='Content flex-1 w-7/8 flex flex-col p-0 bg-gray-100 max-h-full '>
                        {/* <div className='flex flex-col'> */}
                            <ResponseDisplay></ResponseDisplay>
                            {/* <ResponseBox conversation={threads[activeThread].messages} updateConversation={handleConversation}/> */}
                            
                            <InputBox handleConversation={handleConversation} FileAccess ={FileAccess} setFileAccess = {setFileAccess}/>
                        {/* </div> */}
                    </div>                
            </>)
        }else{
            return (<>
                <Sidebar files = {files} updateFileStatus = {updateFileStatus}/>                   
                <FileInterface refreshFiles = {refreshFiles}></FileInterface>
                <FileCards files = {files} setFiles={setFiles}></FileCards>             
            </>)
        }
        return ("<>hello world</>")
    }

    return (
        <div>
            <div className='App w-screen h-screen flex flex-col'>
                {/* <Header/> */}
                <div className=" Header flex h-16 mb-4 bg-gray-500 whitespace-nowrap justify-between">
                    <Header></Header>
                    {/* <DoThingButton/> */}
                    {/* <Tabs setActiveTab={setActiveTab}/> */}
                    <TabButton setActiveTab={setActiveTab}></TabButton>
                    <NavBar />
                    
                    {/* <MobileNavBar /> */}
                </div>
                

            {/* <BlockUi blocking={blocking}> */}
                <div className='Viewport flex-1 w-screen flex flex-row overflow-x-auto '>

                    {TabbedContent()}
                {/* </header> */}
                
                </div>
                <CustomizedSnackbars handleSnackbar = {handleSnackbar} open={Snackbar} msg={SnackbarMsg}></CustomizedSnackbars>
                {/* </BlockUi> */}
            {/* </div> */}
            </div>
            
            
        </div>
    )
}

export default Main