/* Create a checkbox functional component. onChange, log a message to the console. */ 

import React, {useState} from 'react'; 
function Checkbox(props) { 
    const [checked, setChecked] = useState(props.state); 
  
    const handleChange = () => { 
      setChecked(!checked);
      props.changeFileState(!checked, props.id)
    }; 
    
    return ( 
      
      <span>  
        {/* <input type="checkbox" onChange={handleChange}/> */}
        <input id="checked-checkbox" onChange={handleChange} 
            type="checkbox" value="" defaultChecked={checked}
            className=" mx-2 w-4 h-4 text-blue-600  border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
    </span> 
      
    ); 
    
  }; 
  
  export {Checkbox};