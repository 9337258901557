
import React, { useEffect, useState } from "react";
import './Sidebar.styles.css'

// import LoadingIcon from '../LoadingIcon';
// import Sidebar  from '../components/Sidebar';'
// import { DragDropContext } from 'react-beautiful-dnd';
import { Loading } from "../Loading/Loading";
import DraggableList from "../DraggableList/DraggableList";
import MultiDraggableList from "../MultiDraggableList/MultiDraggableList";
import DragNDrop from "../DragNDrop/DragNDrop";
import { Checkbox } from "../checkbox/checkbox";
/// TODO check about adding distribution bundle to reduce load times for the app.

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faDeleteLeft, faFile, faMessage, faPlus, faPlusCircle, faTrash, faX } from '@fortawesome/free-solid-svg-icons'
import { useSearchParams } from "react-router-dom";
import { Tooltip } from "@mui/material";



// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


const MULTIDRAG = true;
function Sidebar(props){
    const [state, setState] = useState([getItems(10), getItems(0)]);
    const [selected, setSelected] = useState(0);
    const [threads, setThreads] = useState(props.threads);
    const [active, setActive] = useState(null)
    const [interact, setInteract] = useState(null)

    const [files, setFiles] = useState(props.files);
    // console.log("files", props.files)fu
    // files.forEach(element => {
    //     element.id = element.source;    
    // });
    // console.log("file sidebar",files);
    // const [activeFiles, setActiveFiles] = useState(files);

    // useEffect(() => {
    //     // props.setFiles(files);
    // }, [files]);
    
    // useEffect(()) = >{

    // }

    // useImperativeHandle(ref, ()=>({

    // }));

    // const selectThread = async(thread)=>{
    //     //select the new thread
    //     // setActive(thread.id);
        
        
    //     // load any saved messages here
    //     props.setActiveThread(thread.id);

    //     return true;
    // }
    
    // function newChat(){
    //     // create a new chat
    //     console.log("new Chat, id is: ",props.threads.length)
    //     const thread = {"id":props.threads.length,"created_by":"user","modified_when":"", "title":"New Chat","messages":[ ]};
    //     // new chat should be at the top of the list of chats
    //     props.setThreads([thread, ...props.threads]);
        
    //     selectThread(thread)
    //     // change the active chat to the new chat
    // }
    // console.log("sidebar threads:", threads)

    // const transitionTimePerPixel = 0.01;
    function ThreadTitle(value){
        return (
        // <ElementMaker
        //     value={value} // Initialize the ElementMaker's value attribute with App's fullName state
        //     handleChange={(e) => setFullName(e.target.value)} // Initialize the ElementMaker's handleChange attribute 
        //                                                         // with a function that updates the state's fullName 
        //                                                         // property with the ElementMaker's <input> element's value
        //     handleDoubleClick={() => setShowInputEle(true)} // Initialize handleDoubleClick with a function that updates
        //                                                     // state's showInputEle property with the Boolean value true
        //     handleBlur={() => setShowInputEle(false)} // Initialize handleBlur with a function that updates state's 
        //                                                 // showInputEle property with the Boolean value false
        //     showInputEle={showInputEle} // Initialized showInputEle with the state's showInputEle property
        // />
        <span>
            {value}
        </span>
        )
    }
    
    function deleteThread(event, id){
    // confirmation button for deleting a thread
    props.setActiveThread(id)
        console.log("delete thread, confirm?", id)
        setInteract(id)
        event.stopPropagation();


    }
    function deleteThreadConfirm(event, id){
        // confirmation button for deleting a thread
            props.setActiveThread(id)
            console.log("delete thread", id)
            setInteract(null)
            props.deleteThread(id)
            event.stopPropagation();
    
        }
    function deleteThreadCancel(event, id){
        // confirmation button for deleting a thread
            props.setActiveThread(id)
            console.log("delete thread, cancel", id)
            setInteract(null)
            event.stopPropagation();
    
    
        }

    
    function ActivateThread(id){
        props.setActiveThread(id)
        // console.log("activate thread", id)
        // console.log("activate interact", interact)
        if (interact != id){
            setInteract(null)
        }
        
    }

    function changeFileState(state, id){
        console.log("change file state", state, id)
        props.updateFileStatus(id, state)
    }
    // conso
    if(props.activeTab === 0){
        // console.log("sidebar", props.threads.length)
        if(props.threads.length === 0 ){
            console.log("loading sidebar")
            return (
                <div className="Sidebar w-1/6 flex flex-col bg-gray-500">
                    <div className="newConvo"><button className="rounded-full border-1 bg-gray-100 w-full p-1 hover:bg-gray-200 truncate"  onClick={() => props.newChat()} >New Chat <FontAwesomeIcon icon={faPlusCircle} /></button></div>        
                    <Loading/>

                </div>
                
            )
        }
        // console.log("sidebar", props.threads)
        // todo add a loading icon here
        // todo add a display that shows the count of elements for each document and a delete button for files.
        return (
            <div className="Sidebar w-1/6 flex flex-col bg-gray-500 overflow-auto">
                <div className="newConvo"><button className="rounded-full border-1 bg-gray-100 w-full p-1 hover:bg-gray-200"  onClick={() => props.newChat()} >New Chat <FontAwesomeIcon icon={faPlusCircle} /></button></div>        
                {<>

                {props.threads.sort((a,b)=> a.updated_when - b.updated_when).map(thread => (
                    <Tooltip title = {thread.title} placement="right-end">
                    <div 
                    key = {thread.id}
                    
                    className={`conversation ${props.activeThread == thread.id && 'active'}`} onClick={() =>
                        ActivateThread(thread.id)} ><FontAwesomeIcon icon={faMessage} className="pt-1" /><span className=" conversationText truncate"> {ThreadTitle(thread.title)} </span>
                            <span className={`delete ${interact == thread.id && 'circle-icon'}`}type="button" onClick={(event)=>deleteThread(event, thread.id)}><FontAwesomeIcon icon={faTrash} /></span>
                            <span className={`confirm ${interact == thread.id && 'interact'}`}type="button" onClick={(event)=>deleteThreadConfirm(event, thread.id)}><FontAwesomeIcon icon={faCheck} /></span>
                            <span className={`cancel ${interact == thread.id && 'interact'}`}type="button" onClick={(event)=>deleteThreadCancel(event, thread.id)}><FontAwesomeIcon icon={faX} /></span>
                        </div>    
                    </Tooltip>
                ))}
                </>
                }
                {/* <div className="thread"><FontAwesomeIcon icon={faMessage} /> text </div> */}
                {/* <div className="thread">text 2</div> */}
                
            </div>

            // <div className="flex mb-4">
            //     <div className="w-full mx-10 bg-gray-300 h-12">
                    
            //     </div>
            // </div>
        );
    }else if (MULTIDRAG){
        
        
        return (
        
        <div className="Sidebar w-1/6 flex flex-col bg-gray-500">
                <div>Files Currently Uploaded</div>
                {/* <DragNDrop lists ={files} updateLists = {setActiveFiles}/> */}

                {props.files.map(file => (
                    <div 
                    key = {file.source}
                    className={`conversation`} ><FontAwesomeIcon icon={faFile} />
                    <Checkbox id = {file.source} state = {file.fileStatus} changeFileState = {changeFileState}/>
                        <span title={file.title}>{file.title} </span>
                    </div>    
                ))}
                
            </div>
        )
    }else{
        return(
            <div className="Sidebar w-1/6 flex flex-col bg-gray-500">
                 {/* <div classname="Title" > Active Files </div> */}
                {/* <DraggableList/> */}
                {/* <MultiDraggableList files = {props.files}/> */}
                {/* <div classname="Title" > Inactive Files </div> */}
                {/* <DraggableList/> */}
        </div>)
    }
  }
  
  export default Sidebar;
  