import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

function BasicCard(props) {
    // console.log("card", props)
    let title = props.file.title || 'title';
    let source = props.file.source || 'source';
    let pages = props.file.pages || '0';
    let embeddings = props.file.embeddings || '0';
    let created_when = props.created_when || "now";
  return (
    <Card variant="outlined" sx={{ width: 320 }}>
      <div>
        <Typography level="title-lg" className ="truncate break-keep" title={title}>{title}</Typography>
        <Typography level="body-sm">{created_when}</Typography>
        <IconButton
          aria-label="bookmark Bahamas Islands"
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
        >
          {/* <BookmarkAdd /> */}
        </IconButton>
      </div>
      {/* <AspectRatio minHeight="120px" maxHeight="200px">
        <img
          src="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286"
          srcSet="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286&dpr=2 2x"
          loading="lazy"
          alt=""
        />
      </AspectRatio> */}
      <CardContent orientation="horizontal">
        <div>
          <Typography level="body-xs">Embeddings</Typography>
          <Typography fontSize="lg" fontWeight="lg">
            {embeddings}
          </Typography>
        </div>
        <div>
          <Typography level="body-xs">Pages</Typography>
          <Typography fontSize="lg" fontWeight="lg">
            {pages}
          </Typography>
        </div>
        {/* <Button
          variant="solid"
          size="md"
          color="primary"
          aria-label="Explore Bahamas Islands"
          sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
        >
          Explore
        </Button> */}
      </CardContent> 
    </Card>
  );
}
export default BasicCard;