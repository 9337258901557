import React from 'react';
import PropTypes from 'prop-types';
//import { Test } from './Loading.styles';

export function Loading(props){
    return (
        // <div className="text-center">
        //   <div className="spinner-border" role="status">
        //     <span className="sr-only">Loading...</span>
        //   </div>
        // </div>
        <div>test</div>
    )
}
    

Loading.propTypes = {
  // bla: PropTypes.string,
};

Loading.defaultProps = {
  // bla: 'test',
};

