

import React, { useState, useRef, useContext, useEffect } from "react";
import axios from "axios";
import {TbSend} from 'react-icons/tb'
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import { Tooltip } from "@mui/material";
// import FormatBold from '@mui/icons-material/FormatBold';




function InputBox(props) {
    const [checked, setChecked] = useState(false);
    const [input, setInput] = useState("");
    const [responses, setResponses] = useState([]);
    const [FileAccess, setFileAccess] = useState(props.FileAccess)

    useEffect(() => {
        props.setFileAccess(FileAccess);
    },[FileAccess]);



    // function SendPrompt(){
    //     axios.post('/v1/completion',{"model":"gpt","content":[{"role": "user", "content": "say Hi to the user"}]}).then(
    //         res => {
    //             console.log(res);
    //             console.log(res.data);
    //         }
    //     )

    // }
    const textBoxRef = useRef(null);

    const PromptChange = (e) =>{
        const prompt = input;   
        console.log(e.key)     ;
        if (e.key === 'C'){
            // console.log("shift enter");
            return true
        }

        if (e.key === 'Enter' && e.shiftKey){
            // console.log("shift enter");
            return true
        }
        if (e.key === 'Enter'){
            // console.log("enter");
            e.preventDefault();
            setInput("");
            
            // e.target.value("")
            // textBoxRef.current.setSelectionRange(0,0);
            // textBoxRef.current.focus();
            sendPrompt(prompt);
            return true
            // return false
        }
        // if (prompt == "" || prompt == "\n"){
        //     console.log("prompt is nothing")
        //     return
        // }

        // console.log("prompt", prompt)
        // prompt = prompt.trim()
        
        // setInput("");
        // textBoxRef.current.setSelectionRange(0,0);
        // textBoxRef.current.focus();

        // if (prompt == "" || prompt == "\n"){
        //     console.log("prompt is nothing")
        //     return
        // }

        return true
      }

    const sendPrompt = () => {


        prompt = input;        
        // console.log("prompt", prompt)
        prompt = prompt.trim()
        
        setInput("");
        // textBoxRef.current.setSelectionRange(0,0);
        textBoxRef.current.focus();

        if (prompt == "" || prompt == "\n"){
            console.log("prompt is nothing")
            return
        }
        try {
            props.handleConversation(prompt);
        } catch (error) {
            console.error("Error sending prompt", error);
        }
    };

     function tempfunc(){
        console.log("hello world");
        sendPrompt();
    }

    function checkHandler(){
        setFileAccess(!FileAccess);
    }
    return (
        
        <div className="flex  mx-1 p-4 text-lg bg-white shadow-lg bg-gray-700 justify-center">

                <form className="flex-1 flex flex-row w-5/6 justify-center bg-gray-500 rounded">
                    {/* <div className="flex-1 w5/6 bg-red-500">test</div>
                    <div></div> */}
                {/* <input classname="border-2 grow rounded py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline " */}
                {/* <input classname="flex-auto border-2 rounded py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline "
                     id="chatInput" type="text" placeholder="Enter your text here"/> */}
                     <Tooltip title="Enter Your Text Here">
                     <textarea className="flex-auto w-4/6 border-2 rounded py-2 px-3 tex-gray-500 leading-tight focus:outline-none focus:shadow-outline resize-y" 
                     ref={textBoxRef} id="chatInput" type="text" placeholder="Enter your text here" value={input} onChange={(e)=> setInput(e.target.value)}
                     onKeyDown={(e)=> {if (e.key ==="Enter"){PromptChange(e); }}}
                     ></textarea>
                    </Tooltip>

                      {/* <Stack spacing={2} direction="row"> */}
      {/* <Button
        variant="outlined"
        color="neutral"
        onClick={() => setPressed(!pressed)}
        aria-pressed={pressed ? 'true' : 'false'}
        sx={(theme) => ({
          [`&[aria-pressed="true"]`]: {
            ...theme.variants.outlinedActive.neutral,
            borderColor: theme.vars.palette.neutral.outlinedHoverBorder,
          },
        })}
      >
        File <br/>
        Access
      </Button> */}
      {/* <IconButton
        variant="outlined"
        color="neutral"
        onClick={() => setPressed(!pressed)}
        aria-pressed={pressed ? 'true' : 'false'}
        sx={(theme) => ({
          [`&[aria-pressed="true"]`]: {
            ...theme.variants.outlinedActive.neutral,
            borderColor: theme.vars.palette.neutral.outlinedHoverBorder,
          },
        })}
      >
        <FormatBold />
      </IconButton> */}
    {/* </Stack> */}
                    <Tooltip title="Send your message to SovereignAI">
                     <button className=" flex-1 m-3 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex place-content-center" 
                            onClick={() => tempfunc()} type="button">
                     <TbSend/>
                    </button>
                    </Tooltip>
            
                </form>


        </div>
        
    );
  }
  
  export default InputBox;
  