import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import './Tabs.styles.css'
export default function TabButton(props) {
  const [alignment, setAlignment] = React.useState(0);
  const[activeTab,setActiveTab]= React.useState(0)
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      
    >
      <ToggleButton  value="Threads" onClick={() => {props.setActiveTab(0)}}>Threads</ToggleButton>
      <ToggleButton  value="Documents" onClick={() => {props.setActiveTab(1)}}>Documents</ToggleButton>
    </ToggleButtonGroup>
  );
}