import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
// import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';

import BasicCard from '../BasicCard/BasicCard';

function FileCards(props) {
  return (
    
    <div className="flex justify-center content-center flex-wrap w-screen h-screen truncate break-keep">
        {props.files.map(file => (<>
            <BasicCard key={file.source} file={file}/>   
            
    </>
        ))}
        
    </div>
  );
}
export default FileCards;