
import React, { useEffect, useState } from "react";
// import LoadingIcon from '../LoadingIcon';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './DraggableList.styles.css'
/// TODO check about adding distribution bundle to reduce load times for the app.

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faDeleteLeft, faFile, faMessage, faPlus, faPlusCircle, faTrash, faX } from '@fortawesome/free-solid-svg-icons'
import { useSearchParams } from "react-router-dom";
import { render } from "react-dom";

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

  // a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  
const grid = 5;


const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
  
    destClone.splice(droppableDestination.index, 0, removed);
  
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
  
    return result;
  };

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
  
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });
  
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
  });

  
      
function MultiDraggableList(props){
    // const [items, setItems] = useState([getItems(10)])
    const [state, setState] = useState([getItems(10), getItems(5, 10)]);


    function deleteItem(ItemIndex, ListInd){
        const newState = [...state];
        newState[ListInd].splice(ItemIndex, 1);
        setState(
        newState.filter(group => group.length)
        );
    }

    const NewItem = ({ItemIndex, ListInd, item, dragItemStyle={}, deleteFn}) => (
        <Draggable
            key={item.id}
            draggableId={item.id}
            index={ItemIndex}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around"
                  }}
                >
                  {item.content}
                  <button
                    type="button"
                    onClick={() => {
                    deleteItem(ItemIndex, ListInd);
                    }}
                >
                    delete
                </button>
                </div>
              </div>
            )}
          </Draggable>
          );

    const List = ({lists, onDragEnd , dragListStyle = {}, ...props}) =>(
        <DragDropContext onDragEnd={onDragEnd} className="flex flex-col">
              {lists.map((el, ind) => (
                <Droppable key={ind} droppableId={`${ind}`}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                        <span>List {ind}</span>
                        {el.map((item, index) => (
                        <NewItem key={item.id} ItemIndex={index} ListInd={ind} item={item} deleteFn={deleteItem}/>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
    )

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
        return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
        const items = reorder(state[sInd], source.index, destination.index);
        const newState = [...state];
        newState[sInd] = items;
        setState(newState);
        } else {
        const result = move(state[sInd], state[dInd], source, destination);
        const newState = [...state];
        newState[sInd] = result[sInd];
        newState[dInd] = result[dInd];

        setState(newState.filter(group => group.length));
        }
    }
    
    return(
        <div>
            
            <List lists={state} onDragEnd={onDragEnd} />
        </div>)

            {/* <span> List 1 </span>
            <List  list={state[0] }
            onDragEnd={handleDragEnd}
              dragItemStyle={{
                background: 'pink',
                borderRadius: '16px',
            }} 
            dragListStyle={{
                background: 'red',
                borderRadius: '16px', 
            }}
            >
                {(item) => (
                    <>
                   <FontAwesomeIcon icon={faFile} />
                    {item.content}
                    </>

                )}
            </List> */}
            {/* <span> List 2 </span>
            <List  list={state[1] }
            onDragEnd={handleDragEnd}
              dragItemStyle={{
                background: 'pink',
                borderRadius: '16px',
            }} 
            dragListStyle={{
                background: 'lightblue',
                borderRadius: '16px', 
                // width: '350px'
            }}
            >
                {(item) => (
                    <>
                   <FontAwesomeIcon icon={faFile} />
                    {item.content}
                    </>

                )}
            </List> */}
            
          
            
        // )

    }
// export {move,getItemStyle,getListStyle,List, Item}
export default MultiDraggableList;