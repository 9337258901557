import React from "react";

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { AuthenticationGuard } from "./components/authentication-guard";

import { Route, Routes, Navigate } from "react-router-dom";

import logo from './logo.svg';
import './App.css';
import Main from './pages/main'
import Landing from './pages/Landing'
import Hello from './pages/helloworld'
import MultiDraggableList from "./components/MultiDraggableList/MultiDraggableList";
import { ProfilePage } from "./pages/profile-page";
import { ProtectedPage } from "./pages/protected-page";
import { PublicPage } from "./pages/public-page";

import { Error } from './pages/Error';
import { Loading } from './pages/Loading';
import { Users } from './pages/Users';

import '@radix-ui/themes/styles.css';


const ProtectedUsers = withAuthenticationRequired(Users);
const ProtectedChat = withAuthenticationRequired(Main);

function App() {
    const { isLoading, error } = useAuth0();
    
    if (isLoading) {
        // return <Loading />;
    }

  return (
    <>
   

    <div className='Wrapper'>
        <div className='App w-screen h-screen flex flex-col'>
            {/* hello world this is the app page */}
            <Routes>
                <Route exact path="/" element={<Navigate replace to="/Main"/>}></Route>
                {/* <Route element={<AuthenticationGuard component={ProfilePage}{<Main/>}path="/Main"></Route> */}
                <Route
                path="/main"
                element={<AuthenticationGuard component={Main} />}
                  />
                <Route element={<ProtectedChat/>}path="/ProtectedMain"></Route>
                <Route element={<Landing/>}path="/Landing"></Route>
                <Route element={<Hello/>}path="/hello"></Route>
                <Route element={<MultiDraggableList/>}path="/DragnDrop"></Route>
                <Route element={<Main/>}path="/callback"></Route>
                {/* <Route path="/" element={<HomePage />} /> */}
                <Route
                    path="/profile"
                    element={<AuthenticationGuard component={ProfilePage} />}
                />
                <Route path="/public" element={<PublicPage />} />
                <Route
                    path="/protected"
                    element={<AuthenticationGuard component={ProtectedPage} />}
                />
                <Route path="/users" element={<ProtectedUsers/>}></Route>
            </Routes>
             
                
                {/* <Route element={<CalendarTab2/>}path="/CalendarTab_2"></Route>
                <Route element={<CalendarTab_Scheduler/>}path="/CalendarTabScheduler"></Route>
                <Route element={<Lessons/>}path="/Lessons"></Route>
                <Route element={<Planner/>}path="/Planner"></Route>
                <Route element={<Manatee/>}path="/Manatee"></Route> */}
                {/* <Route element={<Narwhal/>}path="/narwhal"></Route> */}
                {/* <Route element={<Whale/>}path="/whale"></Route> */}
                {/* <Route element={<Whale/>}path="/whale/:type"></Route> */}
            
        </div>
    </div>
    </>
  );
}

export default App;

