import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './services/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { Route, Link, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import "./styles/styles.css";
import '@fontsource/inter';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//   <React.StrictMode>
    <Provider store={store}>
    <Router>
    <Auth0ProviderWithNavigate>
        <App />
    </Auth0ProviderWithNavigate>
    </Router>
    </Provider>
// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
