import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect(props) {
    const [label, setLabel] = React.useState(props.label);
    const [value, setValue] = React.useState(props.default);
    const {options} = props
    const handleChange = (event) => {
        setValue(event.target.value);
        props.onChange(event.target.value)
    };
    return (
        <Box sx={{ minWidth: 60, maxWidth:120, maxHeight:60, width:100}} className="bg-blue-500 m-5 inline-flex ">
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
            title="Select Model"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label={label}
            onChange={handleChange}
            >
            { options.map((option) => (<MenuItem value={option}>{option}</MenuItem>)
            
            
            )}; 
            </Select>
        </FormControl>
        </Box>
    );
}