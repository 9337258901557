import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from './ResponseBox.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faSpinner, faMessage, faChalkboard,faCircleQuestion,faAsterisk } from '@fortawesome/free-solid-svg-icons'
import { useMarkdownProcessor } from '../../hooks/use-markdown';
import ResponseMsg from '../ResponseMsg/ResponseMsg';
import ResponseBar from '../ResponseBar/ResponseBar';
import {MouseOverPopover} from './popover.jsx';
import { Loading } from '../Loading/Loading';


const defaultModel= "gpt"

function iconSwitch(iconType="announcement"){
    switch(iconType){
        case 'announcement':
            return <FontAwesomeIcon className="pt-1 pr-1" icon={faBullhorn} />;
        case 'user':
            return <FontAwesomeIcon className="pt-1 pr-1" icon={faMessage} />;
        case 'response':
            return <FontAwesomeIcon className="pt-1 pr-1" icon={faChalkboard} />;
        case 'loading':
            return <Loading></Loading>
        default:
            return <FontAwesomeIcon className="pt-1 pr-1" icon={faCircleQuestion} />;

    }
}

function TextLine(message){
    if (message.role === 'loading'){
        return(
            <img className="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt=""></img>
        )

    }else{
        return(
            <div  key = {message.id} className='ResponseBox'>{iconSwitch(message.role)} {message.content}</div>
        )
    }
}

const handleRefClick = (references)=>{
    return () =>{
        console.log(references)
        alert("Reference Clicked!")
    }
}


function ResponseBox(props) {
    const [messagesEnd, setMessagesEnd] = useState(null);
    const scrollToRef = useRef();
    console.log("RSbox", props.Model)
    
    useEffect(() => {
        scrollToRef.current.scrollIntoView()
    },[]);



    function Reference(references){
        if (references !== null && references !== undefined && references.length > 0) {
        //   return (<span onClick={handleRefClick(references)}><FontAwesomeIcon icon={faAsterisk} /></span>)
        return (
        <>
            {/* <button data-popover-target="popover-description" data-popover-placement="bottom-end" type="button">
                <svg className="w-4 h-4 ml-2 text-gray-700 hover:text-gray-900" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                </svg><span className="sr-only">Show information</span>
            </button>
            <div data-popover id="popover-description" role="tooltip" className="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
            <div className="p-3 space-y-2">
                <h3 className="font-semibold text-gray-900 dark:text-white">Activity growth - Incremental</h3>
                <p>Report helps navigate cumulative growth of community activities. Ideally, the chart should have a growing trend, as stagnating chart signifies a significant decrease of community activity.</p>
                <h3 className="font-semibold text-gray-900 dark:text-white">Calculation</h3>
                <p>For each date bucket, the all-time volume of activities is calculated. This means that activities in period n contain all activities up to period n, plus the activities generated by your community in period.</p>
                <a href="#" className="flex items-center font-medium text-blue-600 dark:text-blue-500 dark:hover:text-blue-600 hover:text-blue-700 hover:underline">Read more <svg className="w-2 h-2 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
            </svg></a>
            </div>
            <div data-popper-arrow></div>
            
            </div> */}
            {/* <Popover
      isOpen={isPopoverOpen}
      positions={['bottom','top','right']} // preferred positions by priority
      content={<div>{references}</div>}
    >
        
      <span title="References" className = "References" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
      <FontAwesomeIcon icon={faAsterisk} />
      <i class="fa fa-arrow-circle-down icon-hover"></i>
      </span>
    </Popover> */}
        {<MouseOverPopover content={references}/>}


        </>
        )
        } else {
          return (<span></span>)
        }
    }   
    const handleModelChange = (event) => {
        console.log("handle", event)
        props.handleModelChange(event)
      }
    // const [postResult, setPostResult] = useState('');
      return (
        // <div className="flex h-32 bg-gray-200"></div>
        
        // <div className="flex-1 mb-4 min-h-32">
             <div className="flex-1 mx-1 bg-gray-400  overflow-x-auto py-3 px-5 ResponseContainer relative">
                <ResponseBar handleModelChange={handleModelChange} Model={props.Model || defaultModel} FileAccess ={props.FileAccess} setFileAccess = {props.setFileAccess}></ResponseBar>
                {
                   <>
                   
                   {props.conversation.map((message, ind) => (
                    // key={message.user}
                    // <div className="flex flex-col">

                     <ResponseMsg ind = {ind} message = {message}></ResponseMsg>
                    // <></>
                    //    <div  key = {ind} className='ResponseBox'>{iconSwitch(message.role)} {useMarkdownProcessor(message.content)} {Reference(message.references)}</div>
                   ))}
                   </>
                }
                
            <div style={{float:"left", clear:"both"}}
                ref = {scrollToRef}>                 
            </div> 
            
         </div>
    );
  }

    
  export default ResponseBox;
  